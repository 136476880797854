@import 'shared/style-resources/mixins/layout';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/mixins/breakpoints';

.container {
  @include page-width;
  @include container;
  @include section-inner;
  @include font-kiwi;
}

.title {
  @include font-apple;
  font-weight: bold;
}

.label {
  flex: 2 0 auto;
  margin: 10px 0 0;
}

.buttonContainer {
  display: inline-block;
  margin-top: 20px;
}

.form {
  @include font-peach;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: baseline;

  > p {
    order: 2;
  }

  .inputContainer {
    flex: 1 0 250px;
    margin-left: 24px;
  }
}

@include medium-screens {
  .form {
    flex-wrap: nowrap;

    > p {
      order: initial;
    }
  }

  .label {
    margin: 0 0 0 20px;
  }
}

@include large-screens {
  .form {
    @include font-kiwi;
  }
}
