@import 'shared/style-resources/mixins/resets';

.list {
  ul {
    @include reset-list;
    margin-bottom: 56px;
    break-inside: avoid;
    break-after: column;
  }

  li {
    position: relative;
    padding-left: 24px;
    margin-top: 8px;

    svg {
      position: absolute;
      left: 0;
      top: 0.5em;
    }
  }
}
