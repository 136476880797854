@import 'shared/style-resources/animations';
@import 'shared/style-resources/variables';
@import 'shared/style-resources/mixins/navigation-link';
@import 'shared/style-resources/mixins/typography';

.arrowLink {
  @include navigation-link('active');

  display: inline-block;
  text-decoration: none;
}

.label {
  @include font-peach;
}

.arrow {
  display: inline-block;
  padding-left: 8px;

  .arrowLink:hover & {
    animation: double-bounce 1s both;
  }
}
