@keyframes double-bounce {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(5px);
  }

  40% {
    transform: translateX(0);
  }

  60% {
    transform: translateX(5px);
  }

  80% {
    transform: translateX(0);
  }
}

@keyframes double-bounce-reversed {
  0% {
    transform: translateX(0) rotate(180deg);
  }

  20% {
    transform: translateX(5px) rotate(180deg);
  }

  40% {
    transform: translateX(0) rotate(180deg);
  }

  60% {
    transform: translateX(5px) rotate(180deg);
  }

  80% {
    transform: translateX(0) rotate(180deg);
  }
}
