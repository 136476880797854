@import 'shared/style-resources/mixins/breakpoints';
@import 'shared/style-resources/mixins/resets';
@import 'shared/style-resources/mixins/shapes';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/variables';

.jobTableFilters {
  display: block;
  margin-bottom: 32px;
}

.toggle {
  display: block;
  background: $color-blue-persian;
  padding: 16px;
}

.toggleButton {
  @include reset-button;
  @include font-clementine;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.row {
  display: block;
}

.filters {
  display: none;
  background: $color-blue-persian;
  padding: 8px 0;

  .jobTableFilters.visible & {
    display: flex;
    flex-wrap: wrap;
  }
}

.head {
  display: block;
  padding: 8px 16px;
  text-align: left;
  width: 50%;
  overflow: hidden;
}

.toggleIcon {
  flex-shrink: 0;

  .jobTableFilters:not(.visible) & {
    @include plus($size: 13px, $color: $color-white);
    margin-right: 13px;
  }

  .jobTableFilters.visible & {
    @include minus($size: 13px, $color: $color-white);

    margin-right: calc(13px / 2);
  }
}

@include medium-screens {
  .jobTableFilters {
    display: table-row-group;
  }

  .toggle {
    display: none;
  }

  .filters {
    background: none;
  }

  .row {
    display: table-row;
  }

  .head {
    display: table-cell;
    max-width: 150px;
    padding: 18px 8px 88px;
    background: initial;
  }
}

@include large-screens {
  .head {
    max-width: 250px;
    padding: 18px 18px 72px;
  }
}
