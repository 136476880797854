@import 'shared/style-resources/mixins/breakpoints';
@import 'shared/style-resources/mixins/typography';

.sectionHeading {
  header {
    margin: 198px 0 64px;

    > :first-child {
      padding-left: 0 !important;
    }
  }
}

.externalLink {
  margin: 48px 0;
}

.cta {
  margin: 32px 0;

  .ctaLink {
    text-decoration: none;
  }
}

.sponsors {
  margin: 64px 0;
}

@include medium-screens {
  .cta {
    width: 35%;
    margin: 32px 32px 32px 0;
    float: left;

    &::after {
      content: '';
      clear: both;
    }
  }
}
