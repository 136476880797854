@import 'shared/style-resources/variables';

.label {
  display: flex;
  align-items: baseline;
  cursor: pointer;
}

.input {
  position: absolute;
  pointer-events: none;
  opacity: 0;
}

.box {
  flex-shrink: 0;
  position: relative;
  top: 2px;
  border: 1px solid $color-white;
  width: 16px;
  height: 16px;

  .input:checked + &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: $color-white;
  }
}

.text {
  display: inline-block;
  margin-left: 8px;
  max-width: 500px;
}
