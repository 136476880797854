@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/mixins/layout';
@import 'shared/style-resources/functions';

.inner {
  @include page-width;
  @include container;
}

.values {
  display: inline-block;
  padding: 0;
  margin: 0 0 0 24px;
  list-style-type: none;
  counter-reset: li;
}

.details {
  @include font-peach;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}

.detailsInner {
  margin: 16px 0 8px;
}

.value {
  position: relative;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  &::before {
    @include font-family-mono;
    @include font-grape;
    font-weight: lighter;

    display: inline-block;
    counter-increment: li;
    content: counter(li, decimal-leading-zero);
    position: absolute;
    left: -25px;
    top: 3px;

    transition: all 0.3s ease-in-out;
  }

  &:hover {
    opacity: 1 !important;

    .details {
      max-height: 100vh;
      transition: all 0.5s ease-in-out 0.2s;
    }
  }

  .values:hover & {
    opacity: 0.3;
  }
}

.headline {
  @include font-clementine;
  margin: 16px 0 0;
}

@include medium-screens {
  .values {
    margin-left: columns-to-percent(2);
  }

  .details {
    @include font-peach;
    max-width: columns-to-percent(7);
  }

  .detailsInner {
    margin: 24px 0 16px;
  }

  .value {
    &::before {
      top: 8px;
    }

    &::after {
      content: '';
      width: 1px;
      height: 1px;
      background: #fff;
      position: absolute;
      top: 16px;
      left: -16px;

      transform: translate(-100%, 0);
      transform-origin: center right;
      opacity: 0;
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      &::before {
        transform: translate(-63px, 0);
      }

      &::after {
        opacity: 1;
        transform: translate(-100%, 0) scale(47, 1);
      }

      .details {
        transition: all 0.5s ease-in-out 0.2s;
      }
    }
  }

  .headline {
    @include font-apple;
  }
}

@include large-screens {
  .values {
    margin-left: columns-to-percent(3);
  }

  .details {
    width: 340px;
    max-height: none;

    max-height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;

    opacity: 0;
    transform: translate(100%, 0) translate(16px, 0);
    transition: all 0.2s ease-in-out;
  }

  .value {
    display: flex;

    &::before {
      top: 24px;
    }

    &::after {
      top: 32px;
    }

    &:hover {
      .details {
        opacity: 1;
      }
    }
  }

  .headline {
    @include font-orange;
  }
}
