@import 'shared/style-resources/animations';
@import 'shared/style-resources/mixins/shapes';
@import 'shared/style-resources/variables';

.arrowBox {
  @include black-rectangle(83px);
}

.arrow {
  @include arrow-right;

  .arrowBox.focused &,
  .arrowBox:hover & {
    animation: double-bounce 1s both;
  }
}
