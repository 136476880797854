@import 'shared/style-resources/mixins/breakpoints';
@import 'shared/style-resources/mixins/layout';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/functions';
@import 'shared/style-resources/variables';

.claimSlider {
  padding: 120px 0 0;
  position: relative;
  z-index: $z-tile-content;
}

.inner {
  @include container;
  @include page-width;
  position: relative;
}

.headline {
  width: 150%;
  position: absolute;
  top: 0;
  right: 15%;
  transform: translate(50%, 0);
  z-index: 2;
  font-weight: bold;
  font-size: 250px;
  fill: none;
  stroke-width: 0.25px;
  stroke: rgba(255, 255, 255, 0.7);
  pointer-events: none;

  .slidesLeft & {
    left: 15%;
    transform: translate(-50%, -50%);
    top: 50%;
  }
}

.imageSlider {
  position: relative;
  z-index: 1;
  margin-bottom: 80px;

  .slidesLeft & {
    max-width: columns-to-percent(10);
    margin-left: columns-to-percent(2);
  }
}

.content {
  max-width: columns-to-percent(10);
  position: relative;
  z-index: 3;

  ol {
    margin: 0;
    padding: 0;
    list-style-type: none;
    counter-reset: li;
  }

  p {
    @include font-peach;
    margin: 0 0 16px;
  }

  li {
    display: flex;
    margin: 0 0 16px;

    p {
      @include font-kiwi;
      margin: 0;
    }
  }

  p:empty {
    display: none;
  }

  li::before {
    @include font-family-mono;
    @include font-grape;
    font-weight: lighter;

    margin-right: 12px;
    display: inline-block;
    position: relative;
    top: 3px;
    counter-increment: li;
    content: counter(li, decimal-leading-zero);
  }
}

.link {
  margin-top: 16px;
}

@include medium-screens {
  .claimSlider {
    padding: 150px 0 0;
  }

  .imageSlider {
    width: columns-to-percent(8);
  }

  .link {
    margin-top: 50px;
  }

  .content {
    max-width: columns-to-percent(12);

    ol {
      column-count: 2;
    }

    p {
      @include font-kiwi;
    }

    li {
      break-inside: avoid;

      p {
        @include font-apple;
      }
    }

    li::before {
      top: 7px;
    }
  }

  .slidesLeft {
    display: flex;
    align-items: flex-end;

    .imageSlider {
      width: columns-to-percent(5);
      margin-bottom: 0;
    }

    .content {
      order: -1;
      width: columns-to-percent(5);
    }

    .link {
      margin-bottom: 32px;
    }
  }
}

@include large-screens {
  .content {
    width: columns-to-percent(7);

    p {
      @include font-apple;
    }
  }

  .slidesLeft {
    justify-content: space-between;

    .content {
      width: columns-to-percent(5);
    }
  }

  .link {
    margin-top: 80px;
  }
}
