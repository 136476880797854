@import 'shared/style-resources/functions';
@import 'shared/style-resources/variables';
@import 'shared/style-resources/mixins/breakpoints';
@import 'shared/style-resources/mixins/layout';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/mixins/navigation-link';

.teaser {
  p {
    font-weight: lighter;

    &:empty {
      display: none;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  a {
    text-decoration: none;
    display: inline-block;
  }

  blockquote {
    @include font-clementine;

    margin: 0;

    // stylelint-disable-next-line no-descending-specificity
    p {
      margin: 0;
      font-weight: normal;
      display: inline;
    }
  }
}

.link {
  @include navigation-link('active');
}

.inner {
  @include page-width;
  @include container;
  @include section-inner;
}

.fullscreen {
  min-height: calc(100vh);
  height: 0; // fix/hack to vertically center in IE11

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @include iphone-only {
    min-height: calc(100vh - 100px);
  }
}

.headline {
  @include font-orange;
  margin: 0 0 16px;
}

.about {
  @include font-peach;

  // stylelint-disable-next-line no-descending-specificity
  p {
    font-weight: normal;
  }
}

.cta {
  margin-top: 40px;
}

.iconLinks {
  margin: 32px -12px 0;
  display: flex;
}

.iconLink {
  padding: 0 12px;

  img {
    height: 16px;
    width: auto;
  }
}

.backgroundWrapper {
  mix-blend-mode: lighten;
}

.background {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;
  overflow: hidden;

  img {
    width: 600px;
    max-width: columns-to-percent(12);
    transform: translateX(20%);
  }
}

.backgroundGalleryWrapper {
  opacity: 0.5;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  &::after {
    width: 100%;
    position: absolute;
    content: '';
    bottom: -10px;
    height: 30%;
    background: linear-gradient(to top, $color-black, transparent);
  }
}

.galleryInnerWrapper {
  transform: rotate(-8deg);
  transform-origin: center;

  animation-name: background-gallery;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 45s;

  top: -150px;
  left: -100px;
  position: absolute;
  width: 110vw;
}

.galleryRow {
  display: flex;
}

.galleryItem {
  display: flex;
  padding: 10px;
  flex: 1 0 auto;
}

.galleryImage {
  object-fit: cover;
  object-position: top;
  min-width: 53vh;
  max-height: 53vh;
}

.aboutLarge {
  @include font-clementine;
  color: $color-grey-oslo;

  strong {
    color: #fff;
    font-weight: initial;
  }
}

.mediumHeadline {
  margin-top: 175px;
}

@include medium-screens {
  .teaser {
    blockquote {
      @include font-apple;
      margin: 0;

      p {
        display: block;
      }
    }
  }

  .text {
    max-width: calc(100% / 12 * 9);
  }

  .fullWidth {
    max-width: 100%;
  }

  .headline {
    margin-bottom: 46px;
  }

  .largeHeadline {
    @include font-banana;
  }

  .mediumHeadline {
    @include font-pineapple;
    margin-top: 100px;
  }

  .about {
    @include font-kiwi;
  }

  .iconLinks {
    margin-top: 56px;
  }

  .backgroundWrapper {
    @include page-width;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
  }

  .background {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    max-width: columns-to-percent(7);
  }
}

@include large-screens {
  .teaser {
    blockquote {
      @include font-orange;
      margin: 0;
    }
  }

  .mediumHeadline {
    @include font-pineapple;
  }

  .largeHeadline {
    @include font-mango;
  }

  .about {
    @include font-apple;
  }

  .aboutLarge {
    font-size: 40px;
    line-height: 52px;
  }

  .cta {
    margin-top: 74px;
  }

  .background {
    max-width: columns-to-percent(6);
  }
}

@keyframes background-gallery {
  0% {
    transform: rotate(-3deg) translateX(0);
  }

  100% {
    transform: rotate(-3deg) translateX(-500px);
  }
}

@include medium-vertical-screens {
  .largeHeadline {
    @include font-strawberry;
  }

  .mediumHeadline {
    @include font-melon;
  }

  .text {
    max-width: calc(100% / 12 * 9);
  }

  .about {
    @include font-kiwi;
  }
}
