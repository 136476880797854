@import 'shared/style-resources/mixins/breakpoints';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/variables';

@keyframes moveLeft {
  100% {
    transform: translateX(-50%);
  }
}

@keyframes moveRight {
  100% {
    transform: translateX(0%);
  }
}

.line {
  display: flex;
  align-items: center;
  width: fit-content;
  overflow: hidden;
  white-space: nowrap;

  &.left {
    animation-name: moveLeft;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  &.right {
    transform: translateX(-50%);
    animation-name: moveRight;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

.number {
  font-size: 98px;
  font-weight: bold;
  letter-spacing: -2.45px;
  line-height: 1.2;
  color: $color-black;
  text-shadow: -1px -1px 0 $color-white, 1px -1px 0 $color-white,
    -1px 1px 0 $color-white, 1px 1px 0 $color-white;
  opacity: 0.3;

  &.highlighted {
    opacity: 1;
  }
}

.text {
  @include font-grape;
  margin: 0 34px 0 10px;
  opacity: 0.3;

  &.highlighted {
    opacity: 1;
  }
}

@include medium-screens {
  .number {
    font-size: 140px;
    letter-spacing: -3.5px;
  }

  .text {
    @include font-peach;
    margin: 0 52px 0 16px;
  }
}

@include large-screens {
  .number {
    font-size: 200px;
    letter-spacing: -5px;
  }

  .text {
    @include font-apple;
    margin: 0 94px 0 24px;
  }
}
