@import 'shared/style-resources/variables';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/mixins/breakpoints';

.content {
  z-index: $z-tile-content;
  padding: 24px;
  pointer-events: none;
  transition: opacity 0.1s ease-in;
  opacity: 0;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .selected &,
  .tile:hover & {
    pointer-events: all;
    opacity: 1;
    transition: opacity 0.2s ease-in;
  }
}

.tile {
  position: relative;
  padding-top: 100%;
  height: 100%;
  width: 100%;
  border: 1px solid $color-grey-abbey;
  transition: border 0.1s ease-in;

  &.selected {
    background-color: $color-blue-persian;
    border: 1px solid $color-blue-persian;
    transition: border 0.2s ease-in;
  }

  &:hover {
    z-index: 1;
    border: 1px solid $color-white;
    transition: border 0.2s ease-in;
  }
}

.logo {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $z-tile-logo;
  pointer-events: none;
  height: 100%;
  width: 100%;
  transform: scale(0.7); // TODO: decide if needed ot decided on content level
  filter: saturate(0) brightness(99);

  transition: opacity 0.2s ease-in;
  opacity: 1;

  .selected &,
  .tile:hover & {
    opacity: 0;
    transition: opacity 0.1s ease-in;
  }
}

.status {
  @include font-family-mono;
  @include font-grape;
  line-height: 28px;
  padding: 0 12px;
  margin: 0;
  color: $color-white;
  border: 1px solid transparent;
  position: absolute;
  z-index: 1;
  right: 16px;
  top: 16px;

  transition: opacity 0.2s ease-in, border-color 0.2s ease-in;
  opacity: 0;

  .unicorn & {
    opacity: 1;
    background-color: $color-blue-persian;
    border-color: $color-blue-persian;
  }

  .exited & {
    opacity: 1;
    border-color: $color-white;
  }

  .selected & {
    opacity: 1;
    border-color: transparent;
  }
}

.selectedContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.headline {
  @include font-orange;
  margin: 0;
}

.subheadline {
  @include font-peach;
  overflow: hidden;
  margin: 5% 0 0;
}

@include medium-screens {
  .status {
    @include font-peach;
    line-height: 38px;
    padding: 0 24px;
  }
}
