@import 'shared/style-resources/mixins/breakpoints';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/mixins/resets';
@import 'shared/style-resources/variables';

$gutter: 16px;

.contentGrid {
  display: flex;
  flex-flow: column nowrap;
}

.contentTile {
  background-color: $color-black;
  z-index: $z-tile-content;
  position: relative;
  width: 100%;
  border-bottom: 1px solid transparentize($color-grey-abbey, $line-transparency);
  padding-bottom: 10%;
  margin-bottom: 10%;
}

.flagContainer {
  display: none;
}

.contentTile.pinned {
  order: -1;

  .flagContainer {
    position: absolute;
    right: 16px;
    top: 0;
    display: initial;
  }
}

.tileInner {
  text-decoration: none;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
}

.pictureArea {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  opacity: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.2s ease-in-out;
}

.cta {
  @include font-apple;

  opacity: 0;
  margin: 0;
  text-align: center;
  position: absolute;
  transition: opacity 0.2s ease-in-out;
}

.contentArea {
  flex: auto;
  margin: 10px 0 0;

  display: flex;
  flex-flow: column nowrap;
}

.itemDetailsArea {
  display: flex;

  .mediaSubtype,
  .itemDate,
  .subheadline {
    @include font-grape;
    padding: 4px 6px;
  }

  .dotSeparator {
    @include font-grape;
    font-size: 40px;
  }

  .itemDate,
  .dotSeparator,
  .subheadline {
    margin: 0;
  }

  .mediaSubtype {
    border: 1px solid transparentize($color-white, $line-transparency);
    margin: 0 4px 0 0;
  }
}

.headlineArea {
  flex: 1;
  margin: 16px 16px 16px 0;
  text-decoration: none;
}

.headline {
  @include font-apple;
  display: inline;
  margin: 0;

  br {
    display: none;
  }
}

.eventDetailsArea {
  flex: 1;
  margin: 0 0 20px;
}

.eventDetailsList {
  margin: 0;
}

.eventDetail {
  @include font-grape;
  display: flex;

  dt {
    margin: 0;
    flex: 1;
  }

  dd {
    margin: 0;
    flex: 3;
  }
}

.tagsList {
  @include reset-list;
  display: flex;
  flex-wrap: wrap;
  margin: -4px -10px;
  width: calc(100% + 20px);
}

.tag {
  @include font-family-mono;
  @include font-grape;
  text-transform: lowercase;

  margin: 4px 10px;
  white-space: nowrap;
}

.arrowBox {
  position: absolute;
  bottom: 20px;
  right: 0;
}

.listen {
  .mediaSubtype {
    border-color: transparentize($color-media-listen, $line-transparency);
  }

  .overlay {
    background-color: $color-media-listen;
  }
}

.watch {
  .mediaSubtype {
    border-color: transparentize($color-media-watch, $line-transparency);
  }

  .overlay {
    background-color: $color-media-watch;
  }
}

.read {
  .mediaSubtype {
    border-color: transparentize($color-media-read, $line-transparency);
  }

  .overlay {
    background-color: $color-media-read;
  }
}

.join {
  .mediaSubtype {
    border-color: transparentize($color-media-join, $line-transparency);
  }

  .overlay {
    background-color: $color-media-join;
  }
}

@include small-screens {
  .headline {
    br {
      display: initial;
    }
  }
}

@include medium-screens {
  .contentGrid {
    flex-flow: row wrap;
  }

  .contentTile {
    margin: 16px calc(#{$gutter} / 2);
    flex-basis: calc(50% - #{$gutter});
    border-bottom: none;
    padding-bottom: 0;
  }

  .contentArea {
    min-height: initial;
  }

  .highlightItem,
  .pictureArea:hover,
  .headlineArea:hover {
    .cta,
    .overlay {
      opacity: 0.7;
    }

    .headline {
      border-bottom: 1px solid $color-white;
    }
  }

  .contentTile.pinned {
    border: 1px solid transparentize($color-grey-abbey, $line-transparency);
    flex-basis: 100%;

    .tileInner {
      flex-flow: row-reverse nowrap;
    }

    .pictureArea {
      width: 50%;
      position: static;
    }

    .contentArea {
      flex-shrink: 0;
      width: calc(50% + calc(#{$gutter} / 2));
      justify-content: space-between;
      background-color: $color-black;
      margin: 0;
    }

    .flagContainer {
      right: calc(50% + 16px);
    }

    .itemDetailsArea,
    .headlineArea,
    .eventDetailsArea,
    .tagsList {
      flex: none;
      padding: 5%;
    }

    .headline {
      @include font-orange;
    }

    .eventDetail {
      @include font-peach;
    }

    .picture {
      position: absolute;
      left: 0;
      z-index: -1;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .overlay {
      z-index: -1;
      left: 50%;
      width: 50%;
    }
  }
}

@include large-screens {
  .contentTile {
    flex-basis: calc(33.333% - #{$gutter});
  }

  .contentTile.pinned {
    flex-basis: calc(66.666% - #{$gutter});
  }
}

/* Shapes */
.flag {
  position: relative;
  width: 36px;
  height: 47px;
  padding-top: 15px;
  background: $color-blue-persian;
  color: $color-white;

  .flagText {
    @include font-grape;
    font-weight: bold;
    border: 1.3px solid $color-white;
    text-align: center;
    width: 20px;
    height: 20px;
    margin: auto;
    padding: 1%;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -13px;
    width: 0;
    height: 0;
    background-color: transparent;
    border-bottom: 13px solid transparent;
    border-left: 18px solid $color-blue-persian;
    border-right: 18px solid $color-blue-persian;
  }
}
