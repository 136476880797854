@import 'shared/style-resources/mixins/breakpoints';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/mixins/resets';

.unitValue {
  white-space: nowrap;
}

.value {
  @include reset-input;
  @include font-family-mono;
  @include font-fig;

  width: 50px;
  text-align: right;

  .headline & {
    @include font-apple;
    width: 110px;
    margin-left: 4px;
  }
}

.unit {
  @include font-grape;
  padding-left: 4px;

  .headline & {
    @include font-plum;
  }
}

@include medium-screens {
  .value {
    .large & {
      @include font-orange;
      width: 110px;
    }
  }

  .unit {
    .large & {
      @include font-apple;
    }
  }
}
