@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/variables';
@import 'shared/style-resources/mixins/breakpoints';

.tile:hover {
  opacity: 1;
  transition: opacity 0.3s ease-in 0.1s;
  font-style: italic;
}

.container {
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;

  &:hover .tile:not(:hover) {
    opacity: 0.3;
  }
}

.quote {
  @include font-plum;

  flex-basis: 100%;
  margin: 80px 0;
  position: relative;
  padding: 20px;

  > p {
    @include font-family-sans;
    @include font-peach;
    letter-spacing: initial;
    margin-top: 10px;
  }
  
  blockquote,
  > p {
    margin: 0 0 0 20px;
  }
}

.quoteBegin,
.quoteEnd {
  position: absolute;
}

.quoteBegin {
  top: 0;
  left: 0;
}

.quoteEnd {
  bottom: 0;
  right: 0;
}

@include medium-screens {
  .quote {
    @include font-apple;
    flex-basis: 50%;
    flex-grow: 2;

    blockquote,
    > p {
      margin: 0 70px;
    }
  }

  .quotesRight {
    margin: auto -100px auto 0;

    .quoteBegin {
      top: -20px;
      left: 10px;
    }
  }

  .quotesLeft {
    margin: auto 0 auto -100px;

    .quoteBegin {
      top: initial;
      left: 10px;
    }
  }

  .quoteEnd {
    bottom: 0;
    right: 30px;
  }
}
