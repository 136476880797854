@import 'shared/style-resources/mixins/breakpoints';
@import 'shared/style-resources/mixins/resets';
@import 'shared/style-resources/mixins/typography';

.headline {
  @include font-orange;
  margin: 0;
}

.subline {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subheadline {
  @include font-peach;
  overflow: hidden;
}

.hompageLink {
  display: none;
  text-decoration: none;
}

.highlightListSection,
.contentSection {
  padding: 5% 0;
}

.highlightContentSubSection {
  @include font-apple;
  padding: 5% 0;

  p {
    margin: 0;
  }
}

.extraContentSubSection {
  @include font-peach;
  padding-top: 10%;

  p {
    margin: 0;
  }
}

.highlightListSection {
  @include font-peach;
}

.properties {
  margin: 0;
  display: flex;
  flex-flow: row wrap;
}

.property {
  min-width: 1px;
  flex: 0 1 45%;
  margin: 5% 5% 0 0;
}

.propertyName {
  font-weight: bold;
}

.propertyValue {
  margin: 0;
}

@include medium-screens {
  .header {
    margin-top: 5%;
  }

  .headline {
    @include font-pineapple;
  }

  .subheadline {
    @include font-apple;
    display: flex;
    justify-content: space-between;
  }

  .hompageLink {
    display: initial;
  }

  .main {
    display: flex;
  }

  .contentSection {
    margin-left: 5%;
    flex: 3;
  }

  .highlightContentSubSection {
    @include font-orange;
    padding: 0;
    margin-bottom: 5%;
  }

  .extraContentSubSection {
    padding: 0;
  }

  .highlightListSection {
    flex: 1;
  }

  .properties {
    flex-flow: column wrap;
  }

  .property {
    margin: 0 5% 8% 0;
  }
}
