@import 'shared/style-resources/variables';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/mixins/breakpoints';
@import 'shared/style-resources/mixins/resets';
@import 'shared/style-resources/mixins/layout';
@import 'shared/style-resources/mixins/shapes';

.faqList {
  @include container;
  @include page-width;
}

.inner {
  @include section-inner;
  @include reset-list;
}

.questionContainer {
  @include reset-button;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-top: 1px solid transparentize($color-grey-abbey, $line-transparency);
  border-bottom: 1px solid transparentize($color-grey-abbey, $line-transparency);
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
  cursor: pointer;
  width: 100%;
  text-align: left;

  &:hover,
 &:focus {
    border: 1px solid $color-white;
  }
}

.question {
  @include font-peach;

  letter-spacing: normal;
  margin: 0;
  font-weight: bold;
}

.slideDown {
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

.answerContainer {
  padding: 30px 0;

  p {
    @include font-peach;
    letter-spacing: normal;

    margin: 0;
  }
}

.faqListItem {
  .toggle {
    flex-shrink: 0;
  }

  &.expanded {
    .toggle {
      @include minus($size: 13px, $color: $color-white);
      margin-right: calc(13px / 2);
    }

    .question {
      font-style: italic;
    }
  }

  &:not(.expanded) {
    .toggle {
      @include plus($size: 13px, $color: $color-white);
      margin-right: 13px;
    }
  }
}

@include medium-screens {
  .question {
    @include font-kiwi;
  }
}

@include large-screens {
  .inner {
    margin-left: $indicator-horizontal-line-length;
  }

  .answerContainer {
    p {
      @include font-kiwi;
      letter-spacing: normal;

      margin: 0;
    }
  }
}
