@import 'shared/style-resources/mixins/resets';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/variables';

.input,
.label {
  display: block;
  width: 100%;
}

.inputWrapper {
  position: relative;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
}

.inputValue {
  position: relative;
  font-size: 20px;
  border-bottom: 1px solid transparentize($color-white, $line-transparency);
  padding: 4px 0;
  transition: border-color 0.3s ease-in-out;

  .input:focus + & {
    border-color: $color-white;
  }

  .input.hasError + & {
    border-color: $color-red-promegranate;
  }
}

.placeholder {
  color: $color-grey-oslo;
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
}
