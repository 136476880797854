@import 'shared/style-resources/variables';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/mixins/breakpoints';

.buttonLink {
  @include font-family-sans;
  @include font-peach;
  width: 100%;
  text-align: center;
  text-decoration: none;
  padding: 10px;
}

.blue {
  border: 1px solid $color-blue-persian;
  background-color: $color-blue-persian;
}

.white {
  border: 1px solid $color-white;
  background-color: $color-white;
  color: $color-black;
}

.blue:hover,
.white:hover {
  border: 1px solid $color-white;
  background: none;
  color: $color-white;
}

@include medium-screens {
  .buttonLink {
    width: auto;
    display: inline-block;
    padding: 10px 40px;
  }
}
