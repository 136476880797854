@import 'shared/style-resources/mixins/layout';
@import 'shared/style-resources/variables';

.knowledgeSection {
  @include page-width;
  @include container;

  > * {
    @include section-inner;
  }
}
