@import 'shared/style-resources/variables';
@import 'shared/style-resources/mixins/breakpoints';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/mixins/resets';
@import 'shared/style-resources/mixins/layout';
@import './venture-grid-resources';

.items {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;

  > * {
    height: 100%;
  }
}

.item {
  min-width: 0;
  width: 50%;
  margin-left: -1px;
  margin-top: -1px;
}

.tile {
  cursor: pointer;
  position: relative;
  background-color: transparent;
}

.tileExpansion {
  @include fade-transition;

  grid-area: desc;
  color: $color-black;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-dialog-wrapper;
}

.dialog {
  background-color: $color-blue-persian;
  color: $color-white;
  width: 100vw;
  height: 100%;
}

.dialogInner {
  @include page-width;
  @include container;
  padding-top: 32px;
  padding-bottom: 32px;
  overflow: auto;
  height: 100%;
}

.topArea {
  position: absolute;
  top: 16px;
  right: 16px;
  text-align: right;
}

.closeDetailsButton {
  @include reset-button;
}

.closeDetailsIcon {
  display: block;
  border-radius: 50%;
  width: 33px;
  height: 33px;
  box-shadow: 0 0 5px 5px transparentize($color-black, 0.9);
  background: $color-blue-persian;
}

@include medium-screens {
  .tileExpansion {
    @include accordion-transition;

    position: relative;
    margin: $expansion-margin;
  }

  .dialog {
    overflow: hidden;

    height: auto;

    > * {
      height: auto;
    }
  }

  .topArea {
    position: static;
  }

  .closeDetailsIcon {
    box-shadow: none;
  }

  .item {
    width: 33.333%;

    &:nth-child(3n) {
      .tileExpansion {
        @include adjust-start-position(2, 3);
      }
    }

    &:nth-child(3n - 1) {
      .tileExpansion {
        @include adjust-start-position(1, 3);
      }
    }

    &:nth-child(3n - 2) {
      .tileExpansion {
        @include adjust-start-position(0, 3);
      }
    }
  }
}

@include large-screens {
  .item {
    width: 25%;

    &:nth-child(4n) {
      .tileExpansion {
        @include adjust-start-position(3, 4);
      }
    }

    &:nth-child(4n - 1) {
      .tileExpansion {
        @include adjust-start-position(2, 4);
      }
    }

    &:nth-child(4n - 2) {
      .tileExpansion {
        @include adjust-start-position(1, 4);
      }
    }

    &:nth-child(4n - 3) {
      .tileExpansion {
        @include adjust-start-position(0, 4);
      }
    }
  }
}
