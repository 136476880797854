@import 'shared/style-resources/mixins/resets';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/variables';

.textInput {
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.input,
.label {
  display: block;
  width: 100%;
}

.input {
  @include reset-button;

  cursor: auto;
  font-size: 20px;
  border-bottom: 1px solid transparentize($color-white, $line-transparency);
  padding: 4px 0;

  transition: border-color 0.3s ease-in-out;

  &::placeholder {
    color: $color-grey-oslo;
  }

  &:focus {
    border-color: $color-white;
  }

  &.hasError {
    border-color: $color-red-promegranate;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    padding-left: 6px;
    -webkit-text-fill-color: $color-white;
    box-shadow: 0 0 0 1000px $color-blue-persian inset;

    &::selection {
      background: $color-white;
    }
  }
}
