@import 'shared/style-resources/mixins/breakpoints';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/mixins/layout';

.downloadList {
  @include page-width;
  @include container;
  @include section-inner;

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.downloadTile {
  flex-basis: 99.5%;
  margin-bottom: 34px;

  &:last-child {
    margin-bottom: 0;
  }
}

.downloadLink {
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.imageWrapper {
  position: relative;
  display: block;
  height: 91px;
  width: 79px;
  overflow: hidden;

  img {
    width: inherit;
    position: absolute;
    bottom: 0;
  }

  .overlay {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    background: $color-blue-persian;
    transition: opacity 0.2s ease-in;

    .downloadLink:hover & {
      display: flex;
      opacity: 0.7;
    }
  }
}

.textWrapper {
  width: 100%;
  margin-left: 24px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  .headline {
    @include font-raspberry;

    width: 100%;
    margin-bottom: 8px;
    font-weight: bold;
  }

  .description {
    @include font-cherry;
    @include font-family-mono;
  }

  .downloadIcon {
    margin-left: auto;
    margin-right: 16px;
  }
}

@include medium-screens {
  .textWrapper {
    width: auto;
  }

  .downloadTile {
    flex-basis: 49.5%;
    margin-bottom: 40px;

    &:nth-last-child(-n + 2) {
      margin-bottom: 0;
    }
  }

  .imageWrapper {
    height: 84px;
    width: 74px;
  }

  .textWrapper .downloadIcon {
    margin-left: 16px;
  }
}

@include large-screens {
  .downloadTile {
    flex-basis: 32.5%;

    &:nth-last-child(-n + 3) {
      margin-bottom: 0;
    }
  }
}
