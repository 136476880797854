@import 'shared/style-resources/mixins/layout';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/mixins/resets';

.inner {
  @include page-width;
  @include container;
  @include section-inner;
}

.row {
  width: calc(100% + 128px);
  margin: 0 -64px;

  &.rowCenter {
    text-align: center;
  }
}

.rowHeadline {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: calc(100% - 128px);
  padding-bottom: 8px;
  line-height: 1.3;
  margin: 20px 64px 16px;
  border-bottom: 1px solid transparentize($color-white, $line-transparency);
}

.rowHeadlineText {
  @include font-apple;
  margin: 0 16px 0 0;
}

.rowItem {
  width: 100%;
  padding: 8px 64px;
}

.section {
  margin-bottom: 64px;
}

.subsection {
  transition: background 0.3s ease-in-out;
  margin-bottom: 32px;
}

.highlight {
  background: transparentize($color-white, 0.85);
}

.table {
  width: calc(100% + 16px);
  margin: 0 -8px;
  border-collapse: collapse;

  tr {
    transition: background 0.3s ease-in-out;
  }

  th {
    font-weight: normal;
  }

  td {
    @include font-family-mono;
    width: 0;
    white-space: nowrap;
  }
}

.cell {
  text-align: left;
  vertical-align: top;
  padding: 4px 8px;
  line-height: 1.3;

  .caption {
    @include font-grape;
    margin-top: 2px;
    display: block;
    font-weight: normal;
  }

  .large & {
    @include font-plum;
  }

  &:first-child {
    width: 100%;
  }

  tbody tr:first-child & {
    padding-top: 8px;
  }

  tbody tr:last-child & {
    padding-bottom: 8px;
  }
}

.salaryItem {
  padding: 64px 64px 32px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.salaryItemLabel {
  @include font-clementine;
  margin-right: 16px;
}

.centerItem {
  padding: 16px 32px 32px;
  text-align: center;
}

.centerItemHeadline {
  margin: 0 0 16px;
  line-height: 1.2;
}

.centerItemTitle {
  @include font-pineapple;
}

.centerItemCaption {
  @include font-clementine;
}

@include medium-screens {
  .row {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 128px);
    margin: 0 -64px;

    &.rowCenter {
      justify-content: center;
      align-items: center;
    }
  }

  .rowItem {
    width: 50%;
    padding: 16px 64px;
  }

  .centerItem {
    padding: 64px 64px 128px;
    text-align: center;
  }

  .rowHeadline {
    margin: 40px 64px 32px;
  }

  .rowHeadlineText {
    @include font-orange;
  }

  .cell {
    tbody tr:first-child & {
      padding-top: 12px;
    }

    tbody tr:last-child & {
      padding-bottom: 12px;
    }
  }
}
