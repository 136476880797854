@import 'shared/style-resources/mixins/layout';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/mixins/breakpoints';
@import 'shared/style-resources/variables';
@import 'shared/style-resources/mixins/resets';

.pakconLanding {
  @include page-width;
  @include container;
  @include section-inner;

  section {
    overflow: hidden;

    &:last-child {
      margin-top: 144px;
    }
  }
}

.contentArea {
  p {
    @include font-plum;
    line-height: 40px;
    margin: 16px 0;
  }

  h3 {
    @include font-apple;
    margin: 32px 0 0;
    padding: 0;
  }
}

.subscriptionForm {
  /* stylelint-disable */
  // this is code to override the mailchimp css
  > * {
    font-family: 'IBM Plex Sans', sans-serif !important;
    font-size: 16px !important;
    line-height: 24px !important;
    background-color: $color-black !important;

    h3 {
      @include font-kiwi;
    }

    form {
      padding: 0 !important;
    }

    form div > div {
      width: 100% !important;
      padding-bottom: 10% !important;

      label {
        margin-bottom: 8px !important;
      }
    }

    input[type='submit'] {
      margin-top: 48px !important;
      min-height: 62px !important;
      min-width: 100% !important;
      font-size: 20px !important;
      line-height: 28px !important;
      background-color: transparent !important;
      color: $color-white !important;
      border: 1px solid $color-white !important;
      border-radius: 0 !important;

      &:hover {
        border: 1px solid $color-blue-persian !important;
        background: $color-blue-persian !important;
        color: $color-white !important;
      }
    }

    i {
      // space out descriptions of questions
      margin: 12px 0;
      display: inline-block;
    }
  }
  /* stylelint-enable */
}

.subscriptionFormHeading {
  header {
    margin: 198px 0 64px;

    > :first-child {
      padding-left: 0 !important;
    }
  }
}

@include medium-screens {
  .subscriptionForm {
    /* stylelint-disable */
    // this is code to override the mailchimp css
    > * {
      input[type='submit'] {
        // make submit button more Project A looking
        width: 35% !important;
        min-width: 320px !important;
      }

      form div > div {
        padding-bottom: 3% !important;
      }
    }

    /* stylelint-enable */
  }
}
