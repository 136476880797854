@import 'shared/style-resources/mixins/layout';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/mixins/breakpoints';
@import 'shared/style-resources/mixins/shapes';
@import 'shared/style-resources/functions';
@import 'shared/style-resources/variables';

.teamMemberSlider {
  @include page-width;
  @include container;
}

.pictureArea {
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}

.picture {
  transition: transform 0.3s ease-in-out 0.1s;
  position: absolute;
  top: 0;
  height: 100%;
  object-fit: cover;
}

.arrowBox {
  position: absolute;
  bottom: 20px;
  right: 0;
}

.slideContent {
  opacity: 0.3;
  transition: opacity 0.2s ease-in-out;
  pointer-events: none;

  p {
    margin: 0;
  }

  .firstName {
    @include font-melon;
    margin: 16px 0 0;
  }

  .position {
    @include font-peach;
    margin: 8px 0 48px;
  }

  .link {
    margin-top: 32px;
  }

  &.focused {
    opacity: 1;
    pointer-events: all;

    .highlightItem {
      .picture {
        transform: scale(1.1);
      }
    }
  }

  &:not(.focused) {
    cursor: pointer;
  }
}
