@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/mixins/breakpoints';

.summaryContainer {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.content {
  text-decoration: none;

  &:hover {
    font-style: italic;
  }
}

.name {
  @include font-raspberry;
  margin: 15px 0 5px;
  font-weight: bold;
}

.position {
  @include font-grape;
  margin: 0;
}

.tag {
  @include font-family-mono;
  @include font-cherry;
  margin-top: 12px;
}

.socialLink {
  padding: 0 16px;

  img {
    height: 16px;
    width: auto;
  }
}

@include medium-screens {
  .name {
    @include font-plum;
  }


  .position {
    @include font-fig;
  }
}


