/* Shapes */
@mixin black-rectangle($width) {
  height: calc(#{$width} / 2);
  width: $width;
  background-color: $color-black;
}

@mixin arrow-right {
  position: absolute;
  left: 20%;
  top: 50%;
  width: 60%;
  border-top: 1px solid $color-grey-silver;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: -1px;
    width: 20%;
    border-top: 1px solid $color-grey-silver;
    transform-origin: right center;
  }

  &::before {
    transform: translateX(1px) rotate(45deg);
  }

  &::after {
    transform: translateX(1px) rotate(-45deg);
  }
}

@mixin arrow-left {
  @include arrow-right;
  transform: rotate(180deg);
}

@mixin plus($size, $color) {
  position: relative;
  width: 1px;
  background-color: $color;
  height: $size;

  &::after {
    content: '';
    position: absolute;
    top: calc((#{$size} - 1px) / 2);
    left: calc((#{$size} - 1px) / -2);
    background-color: $color;
    height: 1px;
    width: $size;
  }
}

@mixin minus($size, $color) {
  position: relative;
  height: 1px;
  background-color: $color;
  width: $size;
}
