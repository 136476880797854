@import 'shared/style-resources/mixins/breakpoints';
@import 'shared/style-resources/mixins/resets';

.item {
  padding-right: 15px;
  margin-bottom: 50px;
  flex-basis: 49.5%;
  opacity: 1;
  transition: opacity 0.3s ease-in 0.1s;
}

.moreWrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  .showAll & {
    display: none;
  }
}

.items {
  position: relative;
  max-height: 300px;
  overflow: hidden;

  display: flex;
  flex-flow: row wrap;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100px;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  }

  .showAll & {
    max-height: none;

    &::after {
      content: none;
    }
  }

  &:hover .item:not(:hover) {
    opacity: 0.3;
  }
  
}

.more {
  @include reset-button;
}

@include small-screens {
  .item {
    break-inside: avoid;
  }
}

@include medium-screens {
  .items {
    max-height: none;

    &::after {
      content: none;
    }
  }

  .item {
    flex-basis: 25%;
    margin-bottom: 70px;
  }

  .moreWrapper {
    display: none;
  }
}
