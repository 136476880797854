@import 'shared/style-resources/variables';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/mixins/breakpoints';
@import 'shared/style-resources/mixins/resets';
@import 'shared/style-resources/mixins/shapes';

$group-toggle-size: 8px;

.groupToggleArea {
  display: flex;
  margin-bottom: 16px;
  align-items: center;
}

.groupToggle {
  @include reset-button;
  @include font-raspberry;

  cursor: pointer;
  margin: 0;
}

.groupToggleLine {
  margin-left: 9px;
  border-bottom: 1px solid transparentize($color-white, $line-transparency);
  align-self: center;
  flex: 1;
}

.groupOptions {
  pointer-events: none;
  display: flex;
  flex-wrap: wrap;
}

.plusToggle {
  @include plus(
    $color: transparentize($color-white, $line-transparency),
    $size: $group-toggle-size
  );

  margin-left: -0.5px;
  margin-right: calc(#{$group-toggle-size} / 2 + 9px - 0.5px);
}

.minusToggle {
  @include minus(
    $color: transparentize($color-white, $line-transparency),
    $size: $group-toggle-size
  );

  margin-left: calc(#{$group-toggle-size} / -2);
  margin-right: 9px;
}

.expanded {
  .groupToggleArea {
    margin-bottom: 24px;
  }

  .groupOptions {
    pointer-events: auto;
    margin-bottom: 0;
  }
}

.slideDown {
  transition-duration: 0.2s;
  transition-timing-function: ease-out;

  margin-bottom: 32px;
  margin-top: -8px;
  margin-left: -#{$group-toggle-size};
}
