@import 'shared/style-resources/mixins/layout';

.container {
  @include page-width;
  @include container;
}

.videoArea {
  @include section-inner;
  padding-top: calc(9 / 16) * 100%;
}
