@import 'shared/style-resources/mixins/layout';

.section {
  @include page-width;
  @include container;
  @include section-inner;

  > :first-child {
    margin-bottom: 120px;
  }
}
