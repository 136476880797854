@import 'shared/style-resources/mixins/typography';
@import '../greenhouse-form.module';

.confirmationForm {
  text-align: center;
}

.headline {
  @include form-headline;
}
