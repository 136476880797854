$animation-length: 0.5s;
$dialog-height: 900px;
$expansion-margin: 75px 0;

@mixin adjust-start-position($offset, $columnCount) {
  transform: translateX(calc(-100% * #{$offset}))
    translateX(calc((100vw - (100% * #{$columnCount})) / -2));
}

@mixin fade-transition {
  .dialog {
    transition: opacity $animation-length ease-in-out;
  }

  &.expandEnter {
    .dialog {
      opacity: 0;
    }
  }

  &.expandEnterActive {
    .dialog {
      opacity: 1;
    }
  }

  &.expandExitActive {
    .dialog {
      opacity: 0;
    }
  }
}

@mixin accordion-transition {
  transition: margin $animation-length ease-in-out;

  .dialog {
    transition: all $animation-length ease-in-out;
  }

  &.expandEnter {
    height: $dialog-height;

    .dialog {
      height: 0;
    }
  }

  &.expandEnterActive {
    .dialog {
      height: $dialog-height;
    }
  }

  &.expandExit {
    .dialog {
      height: $dialog-height;
    }
  }

  &.expandExitActive {
    margin: 0;

    .dialog {
      opacity: 0;
      height: 0;
    }
  }
}
