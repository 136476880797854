@import 'shared/style-resources/functions';
@import 'shared/style-resources/mixins/breakpoints';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/variables';

@mixin form-headline {
  @include font-kiwi;
  margin: 0;
  font-weight: bold;
  width: 100%;
}

.form {
  background: transparentize($color-white, 0.93);
  margin: -24px;
  padding: 24px;
}

.fields {
  display: flex;
  flex-wrap: wrap;
  margin-top: 64px;

  &:first-of-type {
    margin-top: 0;
  }
}

.column {
  justify-content: space-between;
}

.headline {
  @include form-headline;
}

.caption {
  @include font-grape;
  color: $color-grey-oslo;
  width: 100%;
}

.field {
  padding: 16px 0;
  width: 100%;

  &.shallow {
    padding-top: 24px;

    & + & {
      padding-top: 0;
    }
  }
}

.text {
  padding: 16px 16px 16px 0;

  :global {
    a {
      display: inline-block;
      text-decoration: none;
      border-bottom: 1px solid $color-white;
    }
  }
}

.buttonWrapper {
  display: inline-block;
  margin: 50px 0 16px;
}

@include medium-screens {
  .half {
    flex: 0 0 calc(50% - 20px);
  }

  .third {
    width: 33.333%;
    padding: 32px 16px 32px 0;
  }
}

@include large-screens {
  .buttonWrapper {
    width: 100%;
  }
}
