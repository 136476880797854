@import 'shared/style-resources/mixins/breakpoints';
@import 'shared/style-resources/variables';

.contentSection {
  margin: 0 0 144px;

  > :first-child {
    // indicator
    margin-bottom: 120px;
  }

  > :not(:first-child):not(:last-child) {
    // inner subSections
    margin-bottom: 144px;
  }

  &.hasBackground {
    padding-top: 52px;
    padding-bottom: 56px;

    > :first-child {
      // indicator
      margin-bottom: 40px;
    }
  }

  &.blue {
    background-color: $color-blue-persian !important;
  }
}

.heroSection {
  margin: 0;

  &:only-child {
    margin: 0 0 144px;
  }
}

@include medium-screens {
  .contentSection {
    margin: 0 0 200px;

    &.hasBackground {
      padding-top: 64px;
      padding-bottom: 72px;

      > :first-child {
        // indicator
        margin-bottom: 56px;
      }
    }
  }

  .heroSection {
    &:only-child {
      margin: 0 0 200px;
    }
  }
}

@include large-screens {
  .contentSection {
    margin: 0 0 200px;

    &.hasBackground {
      padding-top: 88px;
      padding-bottom: 120px;

      > :first-child {
        // indicator
        margin-bottom: 80px;
      }
    }
  }
}
