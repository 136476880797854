@import 'shared/style-resources/mixins/resets';

.filterDropdown {
  position: relative;
}

.label {
  position: relative;
  display: block;
  width: 100%;
  font-weight: lighter;
  white-space: nowrap;
}

.icon {
  position: relative;
  top: -2px;
  padding-left: 10px;
}

.value {
  margin-top: 4px;
  line-height: 25px;
  font-size: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.select {
  @include reset-button;
  color: initial; // prevent unusable color for options text on windows
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: block;
  opacity: 0;
}
