@import 'shared/style-resources/mixins/layout';

.ventureSection {
  @include page-width;
  @include container;

  > :first-child {
    margin-bottom: 121px;
  }

  > * {
    @include section-inner;
  }
}

@include medium-screens {
  .ventureSection {
    > :first-child {
      margin-bottom: 144px;
    }
  }
}
