@import 'shared/style-resources/mixins/layout';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/mixins/breakpoints';

.inner {
  @include page-width;
  @include container;
  @include section-inner;
}

.boxed {
  border: 1px solid $color-white;
  padding: 20px;
  margin: 30px auto;
}

.headline {
  @include font-orange;
  margin: 180px 0 0;
}

.subheadline {
  @include font-peach;
  font-style: italic;
  margin: 16px 0 88px;
}

.content {
  @include font-peach;
  overflow: hidden;

  h2,
  h3 {
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: bold;
  }

  h3 {
    @include font-peach;
    margin: 40px 0 0;
  }

  h2 {
    @include font-apple;
    margin: 88px 0 0;

    + h3 {
      margin: 32px 0 0;
    }
  }

  p {
    margin: 8px 0 0;

    + p {
      margin: 40px 0 0;
    }
  }

  a {
    text-decoration: none;
    border-bottom: 1px solid $color-white;
    display: inline-block;
  }
}

@include medium-screens {
  .text {
    max-width: calc(100% / 12 * 10);
  }

  .headline {
    @include font-banana;
    margin: 310px 0 0;
  }

  .subheadline {
    @include font-kiwi;
    margin: 24px 0 120px;
  }

  .content {
    h2 {
      @include font-orange;
    }
  }

  .boxed {
    margin: 30px 0 0 -20px;
  }
}

@include large-screens {
  .text {
    max-width: calc(100% / 12 * 8);
  }

  .headline {
    @include font-mango;
  }

  .subheadline {
    @include font-orange;
  }

  .content {
    @include font-kiwi;

    h2 {
      @include font-orange;
    }

    h3 {
      @include font-kiwi;
      margin: 64px 0 0;
    }

    p + p {
      margin: 64px 0 0;
    }
  }
}
