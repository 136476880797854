@import 'shared/style-resources/mixins/layout';
@import 'shared/style-resources/mixins/resets';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/functions';

.inner {
  @include page-width;
  @include container;
  @include section-inner;
}

.aboutText {
  @include font-peach;

  p {
    break-inside: avoid;
    margin: 0 0 1em;

    // TODO: revert after content has been fixed
    &:first-child {
      font-size: 20px;
      line-height: 1.5;
    }
  }

  blockquote {
    @include font-apple;
    column-span: all;
    margin: 0;

    // stylelint-disable-next-line
    p {
      margin: 0 0 40px;
    }
  }
}

.aboutFiles {
  margin-top: 48px;
}

.aboutFile {
  margin-top: 8px;
}

.fileLink {
  position: relative;
  text-decoration: none;
  padding-bottom: 2px;

  svg {
    top: 2px;
    position: absolute;
  }
}

.label {
  display: inline-block;
  margin-left: 24px;
  border-bottom: 1px solid transparent;

  .fileLink:hover & {
    border-bottom: 1px solid $color-white;
  }
}

.additional {
  margin-top: 100px;
}

.additionalSection {
  > h2 {
    @include font-raspberry;
    font-weight: bold;
    margin: 0 0 16px;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  p {
    margin: 0;
  }
}

@include medium-screens {
  .aboutText {
    // TODO: revert after content has been fixed
    font-size: 22px;
    line-height: 1.5;
    // column-count: 2;
    // column-gap: 32px;

    p {
      &:first-child {
        font-size: 30px;
      }
    }

    blockquote {
      width: columns-to-percent(11);
    }
  }

  .additionalSection {
    column-count: 2;
    column-gap: 32px;

    // TODO: revert after content has been fixed
    // padding-top: 56px;
    padding-top: 8px;
  }
}

@include large-screens {
  .content {
    display: flex;
  }

  .about {
    width: columns-to-percent(9);
  }

  .aboutText {
    margin-right: columns-to-percent(1);

    blockquote {
      @include font-orange;

      p {
        margin-bottom: 82px;
      }
    }
  }

  .aboutFiles {
    margin-top: 88px;
  }

  .additional {
    margin-top: 0;
    width: columns-to-percent(3);
  }

  .additionalSection {
    column-count: initial;
  }
}
