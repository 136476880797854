@import 'shared/style-resources/variables';
@import 'shared/style-resources/mixins/breakpoints';

.toast {
  background: $color-blue-persian;
  cursor: pointer;
  min-height: 80px;
}

.body {
  color: $color-white;
  text-align: center;
}
