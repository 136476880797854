@import 'shared/style-resources/mixins/layout';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/variables';

.knowledgeHeroItem {
  @include page-width;
  @include container;
  padding-top: 32px;
  padding-bottom: 32px;
}

.itemDetailsArea {
  display: flex;
}

.mediaSubtype,
.itemDate,
.subheadline {
  @include font-grape;
  padding: 6px 12px;
}

.dotSeparator {
  @include font-grape;
  font-size: 40px;
}

.itemDate,
.dotSeparator,
.subheadline {
  margin: 0 0 0 8px;
}

.mediaSubtype {
  border: 1px solid transparentize($color-white, $line-transparency);
  margin: 0;

  .listen & {
    border-color: $color-media-listen;
  }

  .watch & {
    border-color: $color-media-watch;
  }

  .read & {
    border-color: $color-media-read;
  }

  .join & {
    border-color: $color-media-join;
  }
}

.headlineArea {
  flex: 1;
  margin: 20px 0;
}

.headlineLink {
  text-decoration: none;
}

.headline {
  @include font-apple;
  display: inline;
  margin: 0;

  br {
    display: none;
  }

  .headlineLink:hover & {
    border-bottom: 1px solid $color-white;
  }
}

@include small-screens {
  .headline {
    br {
      display: initial;
    }
  }
}

@include medium-screens {
  .mediaSubtype,
  .itemDate,
  .subheadline {
    @include font-plum;
  }

  .dotSeparator {
    @include font-plum;
    font-size: 40px;
  }

  .headline {
    @include font-melon;
  }
}
