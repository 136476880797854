@import 'shared/style-resources/mixins/resets';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/variables';

.input {
  @include reset-input;
  @include font-fig;

  width: 50px;
  text-align: right;
  border-bottom: 1px solid transparentize($color-white, 0.7);
  border-radius: 0;
  transition: border 0.2s ease-in-out;

  &::placeholder {
    color: inherit;
  }

  &:focus {
    border-bottom-color: transparentize($color-white, 0.25);
  }

  .salary & {
    @include font-orange;
    width: 110px;
  }
}

.unit {
  @include font-grape;
  padding-left: 4px;

  .salary & {
    @include font-apple;
  }
}
