@import 'shared/style-resources/mixins/layout';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/variables';

.pressReleaseSection {
  @include page-width;
  @include container;

  > :not(:first-child) {
    @include section-inner;
  }
}

.overviewLinkWrapper {
  display: flex;
  justify-content: center;
  margin-top: calc(80px - 10%);
}

@include medium-screens {
  .overviewLinkWrapper {
    margin-top: 80px;
  }
}
