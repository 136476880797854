@import 'shared/style-resources/mixins/breakpoints';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/mixins/layout';

.jobList {
  @include page-width;
  @include container;
  @include section-inner;
}

.jobs {
  margin: -16px;
  width: calc(100% + 16px);
}

.headline {
  @include font-orange;
  margin-bottom: 56px;
}

@include medium-screens {
  .jobs {
    margin: -18px;
    width: calc(100% + 18px);
  }

  .headline {
    @include font-banana;
    margin-bottom: 120px;
  }
}

@include large-screens {
  .headline {
    @include font-mango;
  }
}
