@import 'shared/style-resources/mixins/breakpoints';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/mixins/layout';

.downloadList {
  @include page-width;
  @include container;
  @include section-inner;

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.downloadTile {
  margin-bottom: 40px;
  flex-basis: 99.5%;

  &:last-child {
    margin-bottom: 0;
  }
}

.downloadLink {
  width: fit-content;
  height: fit-content;
  text-decoration: none;
}

.imageWrapper {
  position: relative;
  display: block;
  height: 195px;
  width: 342px;
  overflow: hidden;

  img {
    width: inherit;
  }

  .overlay {
    @include font-apple;

    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    background: $color-blue-persian;
    transition: opacity 0.2s ease-in;

    .downloadLink:hover & {
      display: flex;
      opacity: 0.7;
    }
  }

  .downloadIconWrapper {
    position: absolute;
    top: 138px;
    right: -1px;
    height: 41px;
    width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-black;
  }
}

.headline {
  @include font-raspberry;

  margin-top: 16px;
  font-weight: bold;
}

@include medium-screens {
  .downloadTile {
    flex-basis: 49.5%;

    &:nth-last-child(-n + 2) {
      margin-bottom: 0;
    }
  }

  .imageWrapper {
    width: 347px;
  }
}

@include large-screens {
  .downloadList {
    justify-content: flex-start;
  }

  .downloadTile {
    flex-basis: 31.5%;
    margin-right: 17px;

    &:nth-last-child(-n + 3) {
      margin-bottom: 0;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  .imageWrapper {
    width: 344px;
  }
}
