@import 'shared/style-resources/functions';
@import 'shared/style-resources/mixins/layout';
@import 'shared/style-resources/mixins/navigation-link';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/variables';

.job {
  @include page-width;
  @include container;
  @include section-inner;
}

.indicatorWrapper {
  position: relative;
  margin-bottom: 120px;
}

.applyIndicatorWrapper {
  margin: 100px 0;
}

.langLinks {
  display: flex;
  position: absolute;
  bottom: 0;
  right: 16px;
  z-index: $z-section-inner;
}

.langLink {
  & + &::before {
    content: '/';
    margin: 0 5px;
  }
}

.langLinkInner {
  @include navigation-link('active');
  font-weight: normal;
  text-decoration: none;

  .langLinkActive & {
    font-weight: bold;
  }
}

.companyInfo {
  @include font-kiwi;
  margin-top: 45px;
  font-weight: bold;

  :global() {
    a {
      text-decoration: none;
      border-bottom: 1px solid $color-white;
      display: inline-block;
    }
  }
}

.content {
  h2 {
    @include font-peach;
    margin-top: 32px;
    font-weight: bold;

    &:first-child {
      margin-top: 0;
    }
  }

  p {
    @include font-peach;
    margin: 8px 0 0;
  }

  ul {
    @include font-peach;
    padding: 0;
    list-style-type: none;

    li {
      position: relative;
      padding-left: 32px;

      &::before {
        position: absolute;
        content: '–';
        left: 8px;
      }
    }
  }
}

@include medium-screens {
  .langLinks {
    right: 32px;
    bottom: 45px;
  }

  .content {
    h2,
    p,
    ul {
      @include font-kiwi;
    }
  }

  .companyInfo {
    @include font-apple;
  }
}

@media (min-width: $page-max-width) {
  .langLinks {
    right: calc((100vw - #{$page-max-width}) / 2 + 32px);
  }
}
