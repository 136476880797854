@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/mixins/resets';
@import 'shared/style-resources/mixins/layout';
@import 'shared/style-resources/animations';
@import 'shared/style-resources/variables';

$border: 0.8px solid $color-grey-abbey;

.wrapper {
  @include page-width;
  @include container;
  @include section-inner;
}

.overviewLinkWrapper {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.overviewLink {
  @include font-family-sans;
  @include font-peach;

  padding: 15px 30px;
  border: 1px solid $color-white;
  text-align: center;
  text-decoration: none;
  width: 100%;

  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: $color-white;
    color: $color-black;
  }
}

.list {
  @include reset-list;

  li:last-child {
    border-bottom: $border;
  }
}

.arrow {
  text-align: right;
  padding: 14px 16px;
}

.row {
  border-top: $border;
  text-decoration: none;
  padding: 16px 0;
  color: $color-white;
  display: flex;
  flex-direction: column;

  &:hover {
    border-color: $color-white;
    color: $color-white;

    .arrow {
      animation: double-bounce 1s both;
    }
  }
}

.date {
  @include font-grape;
  min-width: 150px;
}

.title {
  @include font-clementine;
  margin: 16px 0;
}

@include medium-screens {
  .row {
    color: $color-grey-oslo;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .content {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  .overviewLink {
    width: initial;
  }
}
