@import 'shared/style-resources/mixins/breakpoints';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/mixins/layout';
@import 'shared/style-resources/variables';

$line-animation-duration: 0.6s;

.wrapper {
  .inner {
    @include page-width;
    @include container;
    @include section-inner;

    padding-top: 92px;
  }
}

.sectionIndicator {
  width: 100%;
}

.container {
  position: relative;
}

.headlineVertical,
.headlineHorizontal {
  @include font-family-mono;
  @include font-fig;
  margin: 0;
}

.headlineVertical {
  text-transform: uppercase;
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translate(-50%, -166px) translate(0, -16px);

  transition: opacity $line-animation-duration ease-in-out;
}

.headlineHorizontal {
  padding-top: 8px;
  opacity: 0;

  transition: opacity $line-animation-duration ease-in-out;
  transition-delay: $line-animation-duration;
}

.lineVertical {
  border-left: 1px solid transparentize($color-white, $line-transparency);
  position: absolute;
  bottom: 28px;
  left: 50%;
  height: 166px;
  width: 0;

  transition: all $line-animation-duration ease-in-out;
}

.lineHorizontal {
  border-bottom: 1px solid transparentize($color-white, $line-transparency);
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  width: 0;

  transition: all $line-animation-duration ease-in-out;
  transition-delay: $line-animation-duration;
}

.transition {
  .lineVertical {
    height: 0;
  }

  .lineHorizontal {
    width: $indicator-horizontal-line-length;
    max-width: 50%;
  }

  .headlineVertical {
    opacity: 0;
  }

  .headlineHorizontal {
    opacity: 1;
  }
}

.onlyHorizontal {
  .inner {
    padding-top: 0;
  }

  .headlineVertical {
    text-align: left;
    text-transform: none;
    transform: translate(0, 0);
  }

  .headlineHorizontal,
  .lineHorizontal {
    transition-delay: initial;
  }
}

@include medium-screens {
  .headlineVertical,
  .headlineHorizontal {
    @include font-raspberry;
  }

  .headlineVertical {
    top: -24px;
    transform: translate(-50%, -190px) translate(0, -16px);
  }

  .lineVertical {
    height: 190px;
  }
}

@include medium-vertical-screens {
  .wrapper {
    .inner {
      padding-top: 150px;
    }
  }
}
