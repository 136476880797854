@import 'shared/style-resources/mixins/resets';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/mixins/breakpoints';
@import 'shared/style-resources/mixins/layout';
@import 'shared/style-resources/mixins/shapes';
@import 'shared/style-resources/variables';

.imageSlider {
  width: 100%;
}

.slides {
  overflow: hidden;
  max-width: 100%;
}

.itemContainer {
  width: auto;
  align-self: flex-start;
}

.slidesInner {
  display: flex;
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.controls {
  @include font-cherry;

  padding: 16px 0;
  display: flex;
}

.control {
  @include font-family-mono;
  @include reset-button;

  position: relative;
}

.previousControl {
  .arrow {
    @include arrow-left;
  }
}

.nextControl {
  .arrow {
    @include arrow-right;
  }
}

.control:hover {
  .arrow,
  .arrow::before,
  .arrow::after {
    border-width: 1.8px;
    border-color: $color-white;
  }
}

.status {
  display: flex;
  align-items: center;
  padding: 0 16px;

  .statusDot {
    margin-left: 16px;
    height: 10px;
    width: 10px;
    border: 1px solid $color-white;
    border-radius: 50%;
    background-color: transparent;
    cursor: pointer;
  }

  .statusDot:hover {
    background-color: transparentize($color-white, 0.5);
    border-color: transparentize($color-white, 0.5);
  }

  .statusDot::before {
    content: '';
    padding: 10px;
  }

  .focusedDot {
    background-color: $color-white;
  }
}

/* Modifiers */
.imageSlider.fullWidth {
  .slides {
    width: 100%;
  }

  .controls {
    @include page-width;
    @include container;
  }
}

.imageSlider.multi {
  @include medium-screens {
    .slides {
      overflow: visible;
    }
  }

  .itemContainer {
    margin-left: 16px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.imageSlider.advancedControls {
  .controls {
    justify-content: space-between;
    padding: 32px 0;
  }

  .control {
    @include black-rectangle(83px);
  }

  .status {
    margin-left: -16px;
    padding: 0;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

@include xlarge-screens {
  .imageSlider.fullWidth {
    @include page-width;
    @include container;

    .controls {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
