@import 'shared/style-resources/mixins/layout';
@import 'shared/style-resources/mixins/resets';
@import 'shared/style-resources/mixins/breakpoints';

.wrapper {
  @include page-width;
}

.imageLinkList {
  @include section-inner;
  @include reset-list;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.imageLinkItem {
  width: 100%;
  min-height: 300px;
  align-items: center;
  display: flex;
  padding: 64px;

  a {
    width: 100%;
  }
}

@include medium-screens {
  .imageLinkList {
    flex-direction: row;
    margin-left: -25px;
  }

  .imageLinkItem {
    display: block;
    min-height: auto;
    padding: 18px;

    &:not(:first-child) {
      margin-left: 18px;
    }

    &:first-child {
      padding-left: 0;
    }

    img {
      max-height: 75px;
    }
  }
}
