@import 'shared/style-resources/mixins/layout';
@import 'shared/style-resources/variables';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/mixins/resets';

.inner {
  position: relative;
}

.headlineArea {
  min-height: calc(50vh);
  height: 0; // fix/hack to vertically center in IE11
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  > :first-child {
    position: absolute;
    top: calc(20vh);
    left: 0;
  }
}

.article {
  background-color: $color-white;
  color: $color-black;

  ::selection {
    background: transparentize($color: $color-media-read, $amount: 0.3);
  }
}

.contentArea {
  display: flex;
  flex-direction: column;
  padding: 24px 18px 88px;
}

.content {
  @include font-kiwi;
  flex: 2;
  min-width: 0; // prevents the container from expanding beyond it's allocated space caused by the markdown code snippet

  code {
    @include font-family-sans;
    @include font-raspberry;
    letter-spacing: initial;
    color: $color-blue-persian;
    padding: 3px 6px;
    border: 1px solid $color-grey-silver;
    border-radius: 4px;
  }

  p {
    margin: 16px 0;
  }

  blockquote {
    @include font-apple;
    letter-spacing: 0;
    font-weight: bold;
    line-height: 38px;
    margin: 0;
  }

  h1,
 h2,
 h3 {
    font-weight: bold;
  }

  h1 {
    @include font-apricot;
  }

  h2 {
    @include font-apple;
  }

  h3 {
    @include font-kiwi;
  }

  ul,
  ol {
    @include reset-list;

    li {
      display: flex;

      &::before {
        @include font-kiwi;
        color: $color-blue-persian;
        flex: 1;
      }
    }

    p,
 div {
      margin: 0;
      padding: 0 16px;
      flex: 20;
    }
  }

  ul {
    li {
      &::before {
        content: '• ';
      }
    }
  }

  ol {
    counter-reset: item;

    li {
      &::before {
        content: counter(item) '. ';
        counter-increment: item;
      }
    }
  }

  pre {
    code {
      border: transparent;
      border-radius: initial;
      padding: initial;
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  td,
 th {
    border: 1px solid $color-grey-silver;
    text-align: left;
    padding: 8px;
  }

  img {
    max-width: 100%;
    width: initial;
    margin: 0 auto;
  }

  a {
    @include word-wrap;
  }
}

.tableContainer {
  overflow: auto;
}

.categories {
  flex: 1;
  order: 1;
  margin-top: 80px;
}

.categoryTitle {
  @include font-raspberry;
  line-height: 32px;
  display: none;
  font-weight: bold;
  margin: 0;
}

.tagsList {
  @include reset-list;
  display: flex;
  flex-wrap: wrap;
  margin: -4px -10px;
  width: calc(100% + 20px);
}

.tag {
  @include font-family-mono;
  @include font-grape;
  text-transform: lowercase;

  margin: 4px 10px;
  white-space: nowrap;
}

@include medium-screens {
  .headlineArea {
    min-height: calc(75vh);

    > :first-child {
      top: calc(30vh);
    }
  }

  .contentArea {
    flex-direction: row;
    padding-left: 32px;
    padding-top: 54px;
    padding-bottom: 120px;
  }

  .categories {
    margin-left: 68px;

    &:first-child {
      margin-top: 16px;
    }
  }

  .content {
    blockquote {
      margin: 32px;
    }

    ul,
    ol {
      padding: 0 16px;

      p {
        padding: 0;
      }
    }
  }

  .categoryTitle {
    display: block;
    margin-bottom: 24px;
  }
}

@include large-screens {
  .contentArea {
    padding-left: 188px;
    padding-top: 64px;
    padding-bottom: 200px;
  }

  .categories {
    margin-top: 0;
    margin-left: 196px;
  }
}

@media only screen and (min-width: $page-max-width) {
  .headlineArea {
    > :first-child {
      left: calc((100vw - #{$page-max-width}) / 2);
    }
  }
}
