@import 'shared/style-resources/mixins/resets';
@import 'shared/style-resources/variables';

.dateInput {
  // overriding default react-datepicker styles
  :global {
    // stylelint-disable selector-class-pattern
    input,
    .react-datepicker-wrapper,
    .react-datepicker__input-container {
      display: block;
      width: 100%;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected {
      background-color: $color-blue-persian;
    }

    .react-datepicker-popper {
      z-index: 999;
    }
    // stylelint-enable selector-class-pattern

    input {
      @include reset-button;

      font-size: 20px;
      border-bottom: 1px solid transparentize($color-white, $line-transparency);
      padding: 4px 0;
      transition: border-color 0.3s ease-in-out;

      &::placeholder {
        color: $color-grey-oslo;
      }

      &:focus {
        border-color: $color-white;
      }
    }
  }

  &.hasError {
    :global {
      input {
        border-color: $color-red-promegranate;
      }
    }
  }
}

.inputWrapper {
  position: relative;
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
}
