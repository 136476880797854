@import 'shared/style-resources/mixins/layout';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/mixins/navigation-link';
@import 'shared/style-resources/animations';
@import 'shared/style-resources/variables';

.pressReleaseOverview {
  @include page-width;
  @include container;
  @include section-inner;
  margin-top: 180px;
}

.pressLink {
  @include navigation-link('active');
  @include font-family-mono;
  @include font-apple;
  display: inline-block;
  margin-bottom: 34px;
  text-decoration: none;
}

.arrow {
  display: inline-block;
  margin-right: 12px;
  transform: rotate(180deg);

  .pressLink:hover & {
    animation: double-bounce-reversed 1s both;
  }
}

@include medium-screens {
  .pressReleaseOverview {
    margin-top: 306px;
  }

  .pressLink {
    margin-bottom: 50px;
  }
}

@include large-screens {
  .pressReleaseOverview {
    margin-top: 282px;
  }

  .pressLink {
    margin-bottom: 56px;
  }
}
