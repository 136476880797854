@import 'shared/style-resources/variables';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/mixins/resets';

@mixin active-media-color($color) {
  &.active {
    background-color: $color;
    color: $color-white;
    border-color: transparent;
  }
}

.filterToggleButton {
  @include reset-button;
  @include font-family-mono;
  @include font-fig;

  margin: 8px;
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
  white-space: nowrap;

  transition: all 0.1s ease-in-out;

  // default colors
  background-color: transparentize($color-black, $amount: 0.9);
  color: $color-grey-oslo;
  border: 1px solid $color-grey-oslo;

  &:hover {
    // default colors hover
    background-color: $color-grey-oslo;
    color: $color-white;
    border-color: $color-grey-oslo;
  }

  &.disabled {
    pointer-events: none;

    // default colors disabled
    background-color: transparentize($color-black, $amount: 0.9);
    color: $color-grey-oslo;
    border-color: $color-grey-mineshaft;
  }

  &.active {
    pointer-events: all;

    // default colors active
    background-color: $color-blue-persian;
    color: $color-white;
    text-shadow: 0 0 2px $color-white;
    border-color: $color-blue-persian;
  }

  /* color modifier classes */
  &.themeListen {
    @include active-media-color($color-media-listen);
  }

  &.themeWatch {
    @include active-media-color($color-media-watch);
  }

  &.themeRead {
    @include active-media-color($color-media-read);
  }

  &.themeJoin {
    @include active-media-color($color-media-join);
  }
}
