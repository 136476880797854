@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/mixins/layout';
@import 'shared/style-resources/variables';
@import 'shared/style-resources/mixins/breakpoints';

.container {
  @include page-width;
  @include container;
  @include section-inner;

  .innerContainer {
    display: flex;
    flex-direction: column-reverse;
  }
}

.imgContainer {
  display: flex;
  overflow: visible;
}

.testimonialContainer {
  position: relative;
  background-color: $color-black;
  z-index: $z-success-story;
}

.hiddenQuote {
  display: none;
}

.imgInner {
  min-width: 100%;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.testimonial {
  background-color: $color-black;
  margin-top: 16px;

  .testimonialContent {
    align-items: center;

    .metadata {
      @include font-family-mono;
      @include font-peach;
      font-style: initial;
    }
  }
}

.summary {
  @include font-peach;
}

.quote {
  margin: 0;

  p {
    @include font-clementine;
  }
}

.slidesInner {
  display: flex;
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  max-width: initial;
}

.nextImage,
.hiddenImage {
  opacity: 0;
}

.overviewLinkWrapper {
  display: flex;
  justify-content: center;
  margin-top: 50px;

  .overviewLink {
    @include font-family-sans;
    @include font-peach;
  
    padding: 15px 30px;
    border: 1px solid $color-white;
    text-align: center;
    text-decoration: none;
    width: 100%;
  
    transition: all 0.2s ease-in-out;
  
    &:hover {
      background-color: $color-white;
      color: $color-black;
    }
  }
}

@include medium-screens {
  .container {
    .innerContainer {
      flex-direction: initial;
      min-height: 600px;
    }
  }

  .quote {
    p {
      @include font-orange;
    }
  }

  .testimonial {
    margin-top: 80px;
  }

  .testimonialContainer {
    max-width: 50%;
    padding-right: 30px;

    // required; otherwise the images could be 
    // seen while navigating between items
    padding-left: 32px;
    margin-left: -32px;
  }

  .slidesInner {
    max-width: 50%;
    align-items: center;
  }

  .imgInner {
    padding-right: 30px;
  }

  .overviewLinkWrapper {
    margin-top: 100px;

    .overviewLink {
      width: initial;
    }
  }

  .nextImage {
    opacity: 0.5;
  }
  
}
