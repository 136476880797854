@import 'shared/style-resources/mixins/layout';
@import 'shared/style-resources/mixins/resets';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/variables';

.label {
  position: relative;
  display: flex;
  height: 90px;
  flex-direction: column;
  border: 1px solid transparentize($color-white, $line-transparency);
  border-radius: 5px;
  padding: 8px 16px;

  transition: background 0.3s ease-in-out, border-color 0.3s ease-in-out;

  &:hover {
    background: transparentize($color-white, 0.9);
    border-color: $color-white;
  }

  &.hasError {
    border-color: $color-red-promegranate;
  }
}

.labelText {
  @include font-plum;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fileName {
  @include font-fig;
  margin-top: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.input {
  @include reset-button;
  opacity: 0;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.labelHelp {
  text-align: center;
  font-size: 12px;
  color: $color-grey-oslo;
}

@include medium-screens-only {
  .label {
    height: 190px;
  }
}
