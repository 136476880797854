@import 'shared/style-resources/animations';
@import 'shared/style-resources/mixins/breakpoints';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/variables';

.arrow {
  position: absolute;
  top: 14px;
  right: 16px;
}

.row {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 80px 10px 12px;

  &:hover {
    .arrow {
      animation: double-bounce 1s both;
    }
  }
}

.cell {
  @include font-raspberry;
  display: inline-block;
  padding: 4px;
  font-weight: lighter;

  &.arrowCell {
    padding: 0;
  }
}

.title {
  @include font-peach;
  font-weight: bold;
  width: 100%;
}

// force line within the flex container
.lineBreak {
  width: 100%;
  height: 1px;
  padding: 0;
}

.seniorityLevel,
.organization {
  &::after {
    content: '/';
    margin-left: 6px;
  }
}

.link {
  display: block;
  text-decoration: none;
}

@include medium-screens {
  .row {
    display: table-row;
    padding: 0;
  }

  .cell {
    display: table-cell;
    padding: 12px 8px;
    line-height: 22px;
  }

  .seniorityLevel,
  .organization {
    &::after {
      content: none;
    }
  }

  .lineBreak {
    display: none;
  }

  .arrow {
    position: static;
  }
}

@include large-screens {
  .title {
    @include font-clementine;
  }

  .cell {
    display: table-cell;
    padding: 12px 18px;
    line-height: 22px;
  }
}
