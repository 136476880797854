@import 'shared/style-resources/variables';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/mixins/breakpoints';
@import 'shared/style-resources/mixins/layout';

.container {
  z-index: $z-tile-content;
  flex-basis: 49.5%;
  opacity: 1;
  transition: opacity 0.3s ease-in;
  padding-right: 8px;
  margin-bottom: 50px;
}

.content {
  text-decoration: none;
}

.pictureArea {
  position: relative;
}

.arrowBox {
  position: absolute;
  bottom: 20px;
  right: 0;
}

@include medium-screens {
  .container {
    flex-basis: 25%;
    padding-right: 16px;
  }
}
